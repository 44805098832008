export default {
    toolbars: [
      [
        "simpleupload", //单图上传
        "fullscreen",
        "source",
        "|",
        "undo",
        "redo",
        "|",
        "bold",
        "italic",
        "underline",
        "fontborder",
        "strikethrough",
        "superscript",
        "subscript",
        "removeformat",
        "formatmatch",
        "autotypeset",
        "blockquote",
        "pasteplain",
        "|",
        "forecolor",
        "backcolor",
        "insertorderedlist",
        "insertunorderedlist",
        "selectall",
        "|",
        "rowspacingtop",
        "rowspacingbottom",
        "lineheight",
        "|",
        "customstyle",
        "paragraph",
        "fontfamily",
        "fontsize",
        "|",
        "directionalityltr",
        "directionalityrtl",
        "indent",
        "|",
        "justifyleft",
        "justifycenter",
        "justifyright",
        "justifyjustify",
        "|",
        "touppercase",
        "tolowercase",
        "|",
        "imagenone",
        "imageleft",
        "imageright",
        "imagecenter",
        "|",
        "emotion",
        "map",
        "insertcode",
        "background",
        "|",
        "horizontal",
        "date",
        "time",
        "spechars",
        "|",
        "inserttable",
        "deletetable",
        "insertparagraphbeforetable",
        "insertrow",
        "deleterow",
        "insertcol",
        "deletecol",
        "mergecells",
        "mergeright",
        "mergedown",
        "|",
        "print",
        "preview",
      ],
    ],
    autoHeightEnabled: false, // 编辑器不自动被内容撑高
    initialFrameHeight: 600, // 初始容器高度
    initialFrameWidth: "50%", // 初始容器宽度
    serverUrl: "/config",
    UEDITOR_HOME_URL: "/static/UEditor/",
    maximumWords: "100000000",
  }